import {
  Box,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  FontWeight,
  P,
  Space,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useContext } from "react";
import {
  BrandedComponentType,
  getBrandColorFor,
  retryPaymentFlow,
} from "src/common/utils";
import { GlobalState } from "src/state";

import BrandedButton from "../../BrandedComponents/BrandedButton";
import ExitBRF from "../../ExitBRF";

const NoValidBankAccountsError = () => {
  const {
    payerTheme,
    billingRequest,
    billingRequestFlow,
    setError,
    setBankAuthorisation,
    setShouldCreateNewBankAuth,
  } = useContext(GlobalState);

  return (
    <Box>
      <P size={6} weight={FontWeight.SemiBold}>
        <Trans id="no-valid-bank-accounts-error.title">
          We couldn&apos;t find any valid bank accounts with the bank account
          details you&apos;ve provided
        </Trans>
      </P>
      <Space v={1} />
      <P size={3}>
        <Trans id="no-valid-bank-accounts-error.try-another-bank-message">
          Please try using another bank
        </Trans>
      </P>
      <Space v={1.5} />
      <BrandedButton
        backgroundColor={getBrandColorFor(
          BrandedComponentType.Button,
          payerTheme
        )}
        variant={ButtonVariant.PrimaryOnLight}
        onClick={async () => {
          await retryPaymentFlow(billingRequest);
          setBankAuthorisation(null, () => {
            setShouldCreateNewBankAuth(true);
            setError(undefined);
          });
        }}
        size={ButtonSize.Lg}
        layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      >
        <Trans id="no-valid-bank-accounts-error.use-another-bank">
          Use another bank
        </Trans>
      </BrandedButton>
      <Space v={2} />
      <ExitBRF
        billingRequestFlow={billingRequestFlow}
        billingRequest={billingRequest}
        setError={setError}
        payerTheme={payerTheme}
        fontWeight={FontWeight.SemiBold}
        buttonSize={ButtonSize.Md}
      />
    </Box>
  );
};

export default NoValidBankAccountsError;
