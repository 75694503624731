import { useContext } from "react";
import {
  Box,
  ButtonSize,
  ButtonVariant,
  JustifyContent,
  P,
  FontWeight,
  ButtonLayout,
} from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import { Trans } from "@lingui/macro";
import {
  BrandedComponentType,
  getBrandColorFor,
  isEligibleForFallback,
  retryMandateFlow,
  retryPaymentFlow,
  usesOpenBankingGatewayAisAdapter,
} from "src/common/utils";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import ExitBRF from "src/components/shared/ExitBRF";
import FallbackButton from "src/components/shared/FallbackButton";
import { TrackingEvents } from "src/common/trackingEvents";

export enum BankAuthorisationErrorTestIDs {
  ErrorHeading = "error-heading-bank-auth",
  TryAgainButton = "try-again-button-bank-auth",
  GoBackButton = "go-back-button-bank-auth",
}

const GenericBankAuthError = ({ message }: { message?: string }) => {
  const {
    billingRequest,
    bankAuthorisation,
    billingRequestFlow,
    setError,
    selectedInstitution: institution,
    payerTheme,
    setBankAuthorisation,
  } = useContext(GlobalState);

  const errorMessage = institution?.name ? (
    <Trans id="bank-authorisation-error.bank-connecting-error-message-with-institution-name">
      There has been a problem connecting you to {institution.name}
    </Trans>
  ) : (
    <Trans id="bank-authorisation-error.bank-connecting-error-message">
      There has been a problem connecting you to your bank
    </Trans>
  );

  return (
    <>
      <Box justifyContent={JustifyContent.Center}>
        <P size={6} weight={FontWeight.SemiBold} spaceBelow={1}>
          {message ?? errorMessage}
        </P>
      </Box>
      {usesOpenBankingGatewayAisAdapter(
        billingRequest as BillingRequestResource
      ) && (
        <>
          <P
            size={3}
            spaceBelow={2}
            data-testid={BankAuthorisationErrorTestIDs.ErrorHeading}
          >
            <Trans id="bank-authorisation-error.ais.restart-message">
              Please start again, or use another bank account.
            </Trans>
          </P>
          <Box spaceBelow={1}>
            <BrandedButton
              backgroundColor={getBrandColorFor(
                BrandedComponentType.Button,
                payerTheme
              )}
              variant={ButtonVariant.PrimaryOnLight}
              onClick={async () => {
                await retryMandateFlow(billingRequest);
                setBankAuthorisation(null, () => {
                  setError(undefined);
                });
              }}
              size={ButtonSize.Lg}
              layout={[ButtonLayout.Full, ButtonLayout.Inline]}
              data-testid={BankAuthorisationErrorTestIDs.TryAgainButton}
            >
              <Trans id="Try again">Try again</Trans>
            </BrandedButton>
          </Box>
          <ExitBRF
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            payerTheme={payerTheme}
            setError={setError}
          />
        </>
      )}

      {!usesOpenBankingGatewayAisAdapter(
        billingRequest as BillingRequestResource
      ) && (
        <>
          <P
            size={3}
            spaceBelow={1.5}
            data-testid={BankAuthorisationErrorTestIDs.ErrorHeading}
          >
            <Trans id="bank-authorisation-error.restart-message">
              Please try connecting to your bank account again.
            </Trans>
          </P>
          <BrandedButton
            backgroundColor={getBrandColorFor(
              BrandedComponentType.Button,
              payerTheme
            )}
            variant={ButtonVariant.PrimaryOnLight}
            onClick={async () => {
              await retryPaymentFlow(billingRequest);
              setBankAuthorisation(null, () => {
                setError(undefined);
              });
            }}
            size={ButtonSize.Lg}
            data-testid={BankAuthorisationErrorTestIDs.GoBackButton}
          >
            <Trans id="Try again">Try again</Trans>
          </BrandedButton>
          {isEligibleForFallback(billingRequest, bankAuthorisation) ? (
            <Box spaceAbove={1.5}>
              <FallbackButton
                event={TrackingEvents.BANK_AUTHORISATION_STEP_FALLBACK_CLICKED}
                variant={ButtonVariant.Inline}
                buttonSize={ButtonSize.Md}
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

export default GenericBankAuthError;
