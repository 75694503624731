import { useContext } from "react";
import {
  Box,
  ButtonSize,
  ButtonVariant,
  JustifyContent,
  P,
  ButtonLayout,
  TypePreset,
} from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import {
  BrandedComponentType,
  getBrandColorFor,
  retryPaymentFlow,
} from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { Trans } from "@lingui/macro";

export enum DataTestIds {
  ErrorHeading = "error-heading-bank-auth",
  TryAgainButton = "try-again-button-bank-auth",
}

const ConsentRejectedError = () => {
  const { setError, payerTheme, billingRequest, setBankAuthorisation } =
    useContext(GlobalState);

  return (
    <>
      <Box justifyContent={JustifyContent.Center}>
        <P preset={TypePreset.Heading_06} spaceBelow={1}>
          <Trans id="bank-authorisation-error.consent-rejected.error-message">
            Your payment could not be authorised
          </Trans>
        </P>
      </Box>
      <>
        <P
          preset={TypePreset.Body_02}
          spaceBelow={2}
          data-testid={DataTestIds.ErrorHeading}
        >
          <Trans id="bank-authorisation-error.consent-rejected.restart-message">
            Please try connecting to your bank account again, or try a different
            bank account
          </Trans>
        </P>
        <Box spaceBelow={1}>
          <BrandedButton
            backgroundColor={getBrandColorFor(
              BrandedComponentType.Button,
              payerTheme
            )}
            variant={ButtonVariant.PrimaryOnLight}
            onClick={async () => {
              await retryPaymentFlow(billingRequest);
              setBankAuthorisation(null, () => {
                setError(undefined);
              });
            }}
            size={ButtonSize.Lg}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            data-testid={DataTestIds.TryAgainButton}
          >
            <Trans id="bank-authorisation-error.consent-rejected.restart-button">
              Try again
            </Trans>
          </BrandedButton>
        </Box>
      </>
    </>
  );
};

export default ConsentRejectedError;
