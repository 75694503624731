import { Fragment, useContext } from "react";
import { captureException } from "src/common/sentry";
import BankAuthAlreadyCompleted from "src/components/pages/BankPlaidLink/errors/BankAuthAlreadyCompleted";
import BankAccountMismatchError from "src/components/shared/ErrorsBoundary/BankAccountMismatchError";
import { ErrorType, GlobalState } from "src/state";
import { ErrorTypeEnum, PlaidErrorsEnum } from "src/state/errors";

import Layout from "../Layout";

import BankAccountNotEligibleError from "./BankAccountNotEligibleError";
import BankIdAppNotFound from "./BankIdErrors/AppNotFound";
import BankIdVerificationUnsuccessful from "./BankIdErrors/VerificationUnsuccessful";
import ConsentExpiredError from "./ConsentExpiredError";
import GenericBankAuthError from "./GenericBankAuthError";
import GenericError from "./GenericError";
import InstitutionNotSupportedError from "./InstitutionNotSupportedError";
import LinkExpiredError from "./LinkExpiredError";
import LinkNotWorkingError from "./LinkNotWorkingError";
import PayerCountryNotSupportedError from "./PayerCountryNotSupported";
import RestartError from "./RestartError";
import SessionExpiredError from "./SessionExpiredError";
import NoValidBankAccountsError from "./NoValidBankAccountsError";
import ConsentRejectedError from "./ConsentRejectedError";
import InsufficientFundsError from "./InsufficientFundsError";

// Global state has an error property that is set whenever something goes wrong.
// The ErrorsBoundary watches for an error and renders an error modal if it
// becomes non-null.
const ErrorsBoundary = ({ children }: { children: React.ReactNode }) => {
  const { error } = useContext(GlobalState);

  if (error) {
    return <ErrorsBoundaryView error={error} />;
  }

  // Otherwise render the child elements
  return <>{children}</>;
};

const errorsToIgnoreInSentry = [
  ErrorTypeEnum.LinkExpired,
  ErrorTypeEnum.SessionExpired,
  ErrorTypeEnum.BankAccountMismatch,
  ErrorTypeEnum.BankAuthorisationAlreadyCompleted,
  ErrorTypeEnum.BankAuthorisationError,
  ErrorTypeEnum.NoValidBankAccount,
  ErrorTypeEnum.PayerCountryNotSupported,
  ErrorTypeEnum.BankIdVerificationUnsuccessful,
  ErrorTypeEnum.BankIdVerificationTimeLimitExceeded,
  ErrorTypeEnum.BankIdAppNotFound,
  ErrorTypeEnum.InvalidJWT,
  ErrorTypeEnum.BankAuthConsentRejected,
  ErrorTypeEnum.InstitutionNotSupportedError,
  ErrorTypeEnum.InsufficientFunds,
  ...Object.values(PlaidErrorsEnum),
];

export const ErrorsBoundaryView = ({ error }: { error?: ErrorType }) => {
  // Fire off an event to sentry - ignore certain types
  if (error && !errorsToIgnoreInSentry.includes(error.errorType)) {
    captureException(
      Error(`Error state occured: ${error.errorType} - ${error.errorMessage}`),
      { httpError: error.httpError }
    );
  }

  switch (error?.errorType) {
    case ErrorTypeEnum.LinkExpired:
      return <LinkExpiredError />;
    case ErrorTypeEnum.BankAuthorisationError:
      return <GenericBankAuthError />;
    case ErrorTypeEnum.BankAuthConsentExpired:
      return <ConsentExpiredError />;
    case ErrorTypeEnum.SessionExpired:
      return <SessionExpiredError />;
    case ErrorTypeEnum.BankAccountNotEligibleForScheme:
      return <BankAccountNotEligibleError />;
    case ErrorTypeEnum.BankAccountMismatch: {
      // Conditionally wrap the component in <Layout />,
      // depending on where error is triggered from.
      // TODO: Look for a better workaround for this
      const Wrapper: React.ElementType = error.httpError ? Layout : Fragment;

      return (
        <Wrapper>
          <BankAccountMismatchError />
        </Wrapper>
      );
    }
    case ErrorTypeEnum.BankAuthorisationAlreadyCompleted:
      return <BankAuthAlreadyCompleted />;
    case ErrorTypeEnum.PayerCountryNotSupported:
      return <PayerCountryNotSupportedError />;
    case ErrorTypeEnum.InstitutionNotSupportedError:
      return <InstitutionNotSupportedError />;
    case ErrorTypeEnum.BankIdVerificationUnsuccessful:
      return <BankIdVerificationUnsuccessful />;
    case ErrorTypeEnum.BankIdVerificationTimeLimitExceeded:
      return <BankIdVerificationUnsuccessful timeLimitExceeded />;
    case ErrorTypeEnum.BankIdAppNotFound:
      return <BankIdAppNotFound />;
    case ErrorTypeEnum.ResumeUnauthorisedError:
      return <LinkNotWorkingError />;
    case ErrorTypeEnum.NoValidBankAccount:
      return <NoValidBankAccountsError />;
    case ErrorTypeEnum.BankAuthConsentRejected:
      return <ConsentRejectedError />;
    case ErrorTypeEnum.InsufficientFunds:
      return <InsufficientFundsError />;
    case ErrorTypeEnum.PaymentRequestError:
    case ErrorTypeEnum.InstitutionError:
    case ErrorTypeEnum.ApiError:
      return <RestartError />;
    default:
      return <GenericError />;
  }
};

export default ErrorsBoundary;
